@import '../../../themes/variables.module.scss';
// ----------------------------------------------------------------------------
// Botoes de Selecao
.content-pendencia-demenda {
  .Selector {
    margin-top: 30px;
    margin-bottom: 15px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .Selector .btn-lg,
  .btn-group-lg > .btn {
    border-radius: 0.7rem;
    font-size: 32px;
    font-weight: bold;
    padding: 3px;
    padding-left: 80px;
    padding-right: 80px;
  }

  .Selector .btn-primary {
    border-width: 4px;
  }

  .Selector .btn-outline-primary {
    border-width: 4px;
  }

  // ----------------------------------------------------------------------------
  // Link para ajuda sobre o uso da pagina

  .demanda-help-link {
    display: block;
    padding-bottom: 60px;
  }
}
