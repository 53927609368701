.box-info {
  width: 100%;
  max-width: 1024px;
  margin: 40px auto;

  text-align: center;
  font-weight: 500;
  font-size: 1.5em;
}

.box-input-data {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}

.box-input-data ~ .buttons-navigation {
  margin-top: 30px;
}
