.filter-query {
  .filter-data {
    max-width: 180px;
  }

  form {
    .query-button {
      margin-right: 10px;
      border-radius: 10px;
      min-width: 150px;
    }
  }
}
