@import '../../../themes/variables.module.scss';
.password-changed {
  text-align: center;

  .icon-check {
    color: $sucess;
    font-size: 100px;
    width: 100%;
  }

  label {
    font-size: 30px;
    font-weight: 500;
    margin: 10px 0 30px 0;
  }

  p {
    font-size: 22px;
  }
}
