.wrapper-atendimento {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;

    .contato,
    .abrirChamado,
    .duvidas {
      margin-bottom: 25px;
      padding: 20px;
    }
  }
}
