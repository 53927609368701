.extrato-downloads {
  margin: 20px;
  font-size: 20px;

  .btn-extrato {
    border-radius: 10px;
  }

  th {
    font-size: 24px;
  }
}
