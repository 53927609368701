@font-face {
  font-family: 'Stag';
  src: url('../assets/fonts/Stag-Sans-Bold.otf');
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'Stag';
  src: url('../assets/fonts/Stag-Sans-Bold-Italic.otf');
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: 'Stag';
  src: url('../assets/fonts/Stag-Sans-Medium.otf');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Stag';
  src: url('../assets/fonts/Stag-Sans-Medium-Italic.otf');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: 'Stag';
  src: url('../assets/fonts/Stag-Sans-Light.otf');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Stag';
  src: url('../assets/fonts/Stag-Sans-Light-Italic.otf');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Stag';
  src: url('../assets/fonts/Stag-Sans-Medium.otf');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Stag';
  src: url('../assets/fonts/Stag-Sans-Medium-Italic.otf');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Stag';
  src: url('../assets/fonts/Stag-Sans-Book.otf');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Stag';
  src: url('../assets/fonts/Stag-Sans-Book-Italic.otf');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'Stag';
  src: url('../assets/fonts/Stag-Sans-Light.otf');
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'Stag';
  src: url('../assets/fonts/Stag-Sans-Light-Italic.otf');
  font-style: italic;
  font-weight: 200;
}
