@import '../../../../themes/variables.module.scss';

.form-dados-acao {
  .titulo-acao-promocional {
    margin-left: -30px;
    margin-right: -30px;
    margin-bottom: 15px;
    padding: 10px 30px;
    background-color: $gray-light;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
  }
  .promocional {
    max-width: 100%;
    max-height: 90px;
    margin-top: 8px;
  }
  .form-promocional {
    max-width: 120px;
    min-width: 80px;
  }
  label {
    font-size: 13px;
  }
}
