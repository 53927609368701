@import '../../../themes/variables.module.scss';
.content-column {
  flex-direction: column;
  overflow: auto;

  .content-cards {
    overflow: auto;
    width: 100%;
    padding: 10px;
    max-width: 1024px;
    margin: 0 auto;

    @media screen and (min-width: 993px) {
      display: flex;
      justify-content: center;
    }
  }

  .buttons-navigation {
    display: flex;
    max-width: 1024px;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 40px;
    justify-content: space-between;

    button {
      width: 200px;
      height: 62px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      &:nth-of-type(1) {
        span {
          display: flex;
          &:nth-of-type(1) {
            flex: 1;
            font-size: 1.5rem;
            justify-content: flex-end;
          }
          &:nth-of-type(2) {
            flex: 3;
            justify-content: center;
          }
        }
      }
      &:nth-of-type(2) {
        span {
          display: flex;
          &:nth-of-type(1) {
            flex: 3;
            justify-content: center;
          }
          &:nth-of-type(2) {
            flex: 1;
            font-size: 1.5rem;
            justify-content: center;
          }
        }
      }
    }
  }
}
