@import '../../../themes/variables.module.scss';
@import '../../../themes/mixins.scss';

.header-app {
  width: 100%;
  height: 60px;
  background-color: $primary;
  background-image: linear-gradient($primary, $secondary);
  display: flex;

  @media screen and (min-width: 321px) {
    height: 70px;
  }

  .brand-app {
    flex: 4;
    display: flex;
    justify-content: center;

    img {
      max-width: 130px;
      margin-top: 3px;
    }

    @media screen and (min-width: 993px) {
      flex: 3;
      justify-content: inherit;
    }
  }
}
