@import '../../../themes/variables.module.scss';

.wrapper-content-pendencias {
  min-height: calc(100vh - 120px);

  @media screen and (min-width: 322px) {
    min-height: calc(100vh - 140px);
  }

  // Forca espacamento fixo para numeros e datas para melhor visualizacao dos dados em colunas
  tbody tr td.monospace {
    font-family: monospace;
  }

  tr.alerta {
    color: $danger;
  }

  .content-pendencias {
    padding: 30px;
    border-bottom: 1px solid $gray-light;
    justify-content: center;
    width: 100%;
    margin: 0 auto;

    .nav-tabs .nav-link.active {
      border: 2px solid;
      border-radius: 10px;
      border-color: transparent;
      color: $white;
      background-color: $primary;

      span {
        color: $white;
      }
    }
    .nav-tabs .nav-link.active:hover {
      border: 2px solid;
      border-radius: 10px;
      border-color: transparent;
      color: $white;
      background-color: $primary;

      span {
        color: $white;
      }
    }
    .nav-tabs .nav-link {
      color: $primary;
      span {
        font-size: large;
        color: $primary;
      }
    }
    .nav-tabs .nav-link:hover {
      color: $primary-hover;
      span {
        color: $primary-hover;
      }
    }
    .nav-tabs {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      font-weight: 800;
      font-size: large;
      border-bottom: none;
      margin-bottom: 10px;
      max-width: 1600px;
      margin: auto;

      .nav-link {
        border: none;
        margin: 5px;
      }
      .nav-link:focus {
        outline: 0px;
      }

      .badge-danger {
        color: $gray;
        background-color: transparent;
      }
    }
    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .offset-lg-3 {
        padding: 0;
      }
      .filter-pendencias {
        display: flex;
        flex-direction: row;
        justify-content: left;

        .query-button {
          margin-right: 10px;
          border-radius: 10px;
          min-width: 150px;
        }
      }
    }
    .pesquisa {
      align-items: center;
      max-width: 850px;
      font-size: inherit;
      font-weight: 400;
      font-size: 1rem;

      .tipo {
        justify-content: center;
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;

        label {
          margin-bottom: 0;
          padding-top: 7px;
        }

        div {
          text-align: left;
          input {
            text-align: start;
          }
        }
      }
    }

    .table-query {
      max-width: 1600px;
      min-height: 494px;
      margin: 0 auto;
      margin-top: 30px;
      padding: 0;

      .table-responsive {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        th {
          font-weight: 900;
          border-top: none;
          padding: 0.5rem;
        }

        td {
          padding: 0.5rem;
        }
      }

      .tbody {
        .table-button {
          border-radius: 10px;
          min-width: 140px;
          font-size: 14px;
          height: 28px;
          padding-top: 2px;
        }
      }
      tbody tr:nth-of-type(odd) {
        background-color: $turn-off-the-lighter;
      }
    }
  }
  @media screen and (min-width: 994px) {
    .tipo {
      text-align: right;
    }
  }

  @media screen and (max-width: 400px) {
    .content-pendencias {
      .row {
        margin: 0;

        .filter-pendencias {
          display: flex;
          flex-direction: column;
          padding: 0;

          .query-button {
            margin: 5px;
            margin-right: 10px;
            border-radius: 10px;
            min-width: 150px;
          }
        }
      }
      .pesquisa {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 0;

        .tipo {
          display: flex;
          flex-direction: column;
          text-align: center;
        }
      }
    }
  }
}
