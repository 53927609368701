.wrapper-dados-usuario {
  .content-meus-dados {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
  }
}

.dados,
.senha {
  margin-bottom: 25px;
  padding: 20px;
}
