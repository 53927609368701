@import '../../../../../themes/variables.module.scss';

.modal-solicitacaofluxo-produtos {
  .modal-dialog {
    max-width: 1000px;
    font-weight: 200;
  }

  .row-produto {
    background: $gray-light;
    padding: 10px;

    label {
      font-weight: bold;
      display: block;
      margin: 0;
    }

    .preco-atual {
      color: $gray;
      cursor: not-allowed;
    }
  }

  button {
    border-radius: 10px;
    font-size: 18px;
    text-transform: uppercase;
    padding: 9px 30px;
    border: none;
  }
}
