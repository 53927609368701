@import '../../../themes/variables.module.scss';
// ----------------------------------------------------------------------------
// Tabela

thead tr th {
  color: $gray;
}

tbody tr {
  font-size: 20px;
  color: $gray;
}

tbody tr:nth-of-type(odd) {
  background-color: $turn-off-the-lighter;
}

tr.alerta {
  color: $danger;
}

// Forca espacamento fixo para numeros e datas para melhor visualizacao dos dados em colunas
tbody tr td.monospace {
  font-family: monospace;
}

.button-action {
  margin-left: 10px;
  margin-right: 10px;
}

// ----------------------------------------------------------------------------
// Painel dos filtros

.filter-box {
  margin: 10px;
  padding-left: 20px;
  padding-right: 5px;
  padding-bottom: 10px;
  max-width: 1600px;
  margin: auto;
}

// ----------------------------------------------------------------------------
// Cantos arredondados para Input do numero da solicitacao

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.input-group-append .btn {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
