@import '../../../themes/variables.module.scss';

.wrapper-content-alterar-solicitacao {
  position: relative;
  min-height: calc(100vh - 120px);
  max-width: 1280px;
  margin: auto;

  @media screen and (min-width: 322px) {
    min-height: calc(100vh - 140px);
  }
}
