@import '../../../../../themes/variables.module.scss';

.wrapper-content-pedido-entrega-digital {
  .content-pedido-entrega-digital {
    position: relative;
    min-height: calc(100vh - 120px);
    max-width: 1024px;
    margin: auto;

    .buttons {
      .flex-row-reverse {
        display: flex;
      }
    }

    @media screen and (min-width: 322px) {
      min-height: calc(100vh - 140px);
    }
  }
}
@media screen and (max-width: 480px) {
  .content-pedido-entrega-digital {
    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .flex-row-reverse {
      }
      div {
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
      }
    }
  }
}
