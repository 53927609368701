@import '../../themes/variables.module.scss';

.logged-user {
  flex: 15;
  display: none;
  color: $white;
  font-size: 1.3em;
  p {
    margin: 15px 10px;
  }

  @media screen and (min-width: 993px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
