@import '../../../../themes/variables.module.scss';

.table-query {
  max-width: 1600px;
  margin: 0 auto;
  padding: 15px;

  th {
    font-weight: 900;
    border-top: none;
  }

  td {
    padding: 0.5rem;
  }

  .table-button {
    border-radius: 10px;
    min-width: 140px;
    font-size: 14px;
    height: 28px;
    padding-top: 2px;
  }

  .cancelado {
    color: $danger;
  }
}
